@import '../../theme/main.scss';

.student-profile-card-default-page-setting {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.student-profile-card-container {
  width: 300px;
  height: auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.student-profile-card-upper-part {
  height: 100px;
  background-color: #6617cb;
  background-image: linear-gradient(315deg, #6617cb 0%, #cb218e 74%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 10px 10px 0px 0px;
}

.student-profile-card-upper-part__image img {
  transform: translate(0px, 50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: black;
  padding: 3px;
}

.student-profile-card-lower-part {
  text-align: center;
  margin-top: 54px !important;
}

.student-profile-card-lower-part__name {
  text-align: center;
}

.student-profile-card-lower-part__name__firstname {
  display: inline-block !important;
}

.student-profile-card-lower-part__name__lastname {
  display: inline-block !important;
  margin: 5px;
}

.student-profile-card-lower-part__bio {
  padding: 15px;
  margin-top: 30px;
  text-align: center;
}

.student-profile-card-lower-part__age {
  margin-top: 20px;
  margin-bottom: 20px;
}
