@import '/src/theme/main.scss';

.course-comments {
  &__card {
    margin: 35px 16px !important;
    padding: 32px !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25) !important;
    border-radius: 16px !important;
  }
}

.course-comments-divider {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
