@import '../../theme/main.scss';
.BankCard {
  justify-content: center;
  background-attachment: fixed;
  background-size: cover;
  background-attachment: fixed;
  text-align: center;
  padding: 8px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px !important;
  border-radius: 10px;
  box-shadow: rgb(42, 31, 75) 0px 2px 10px;
  background-color: rgb(16, 97, 100);
}
.CardNumber {
  margin-top: 0.5vmin !important;
  font-weight: 800;
  font-size: large;
  color: rgb(6, 7, 58);
}
.CardName {

  font-weight: 700 !important;
  color: rgb(207, 207, 207) !important;
  margin-top: 1vmin !important;
  width: 220px !important;
}

.credit-card-input {
  padding: 5px;
  height: 60px !important;
  background-color: transparent;
  border: none;
  color: white;

  @media (max-width: 2500px) {
    font-size: 4vmin !important;
    width: 10vmin !important;
    margin-bottom: 2.5vmin !important;
    margin-top: 50px !important;
  }

  @media (max-width: 1500px) {
    font-size: 4vmin !important;
    width: 10vmin !important;
    margin-top: 50px !important;
  }

  @media (max-width: 1200px) {
    font-size: 4vmin !important;
    width: 10vmin !important;
    margin-bottom: 2.5vmin !important;
    margin-top: 50px !important;
  }

  @media (max-width: 900px) {
    font-size: 4vmin !important;
    width: 10vmin !important;
    margin-bottom: 2.8vmin !important;
    margin-top: 5vmin !important;
  }

  @media (max-width: 700px) {
    font-size: 4vmin !important;
    width: 10vmin !important;
    margin-bottom: 0vmin !important;
    margin-top: 3.5vmin !important;
  }

  @media (max-width: 600px) {
    font-size: 7vmin !important;
    width: 15vmin !important;
    margin-bottom: 3vmin !important;
    margin-top: 6.5vmin !important;
  }

  @media (max-width: 460px) {
    margin-bottom: 1vmin !important;
    margin-top: 3.5vmin !important;
  }

  @media (max-width: 320px) {
    margin-top: 0vmin !important;
  }


  &:focus {
    outline: none !important;
  }

  &::placeholder {
    color: rgb(201, 193, 193);
  }
}

.shaba {
  padding: 5px !important;
  height: 40px !important;
  background-color: transparent;
  border: none;
  color: white;


  @media (max-width: 2500px) {
    font-size: 1.5vmin !important;
    width: 30vmin !important;
    letter-spacing: 3px !important;
  }

  @media (max-width: 1500px) {
    font-size: 1.5vmin !important;
    width: 30vmin !important;
    letter-spacing: 3px !important;
  }

  @media (max-width: 1200px) {
    font-size: 1.5vmin !important;
    width: 30vmin !important;
    letter-spacing: 3px !important;
  }

  @media (max-width: 900px) {
    font-size: 1.5vmin !important;
    width: 30vmin !important;
    letter-spacing: 2.2px !important;
  }

  @media (max-width: 700px) {
    font-size: 1.7vmin !important;
    width: 32vmin !important;
    letter-spacing: 2px !important;
  }

  @media (max-width: 600px) {
    font-size: 2.3vmin !important;
    width: 43vmin !important;
    letter-spacing: 2px !important;
  }

  @media (max-width: 460px) {
    font-size: 2.3vmin !important;
    width: 44vmin !important;
    letter-spacing: 1.8px !important;
  }

  @media (max-width: 380px) {
    font-size: 2.3vmin !important;
    width: 43vmin !important;
    letter-spacing: 1.5px !important;
  }

  &:focus {
    outline: none !important;
  }

  &::placeholder {
    color: rgb(201, 193, 193);
    font-weight: 1000px !important;
    letter-spacing: 3px !important;
    @media (min-width: 900px) {
      letter-spacing: 3.5px !important;

    }
  }
}

.credit-card-submit {
  background-color: rgb(26, 128, 97) !important;
}
